import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckLogin } from './providers/check-tutorial.service';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'tutorial',
  //   loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
  //   canLoad: [CheckTutorial]
  // },
  {
    path: 'facturacion-historico',
    loadChildren: () => import('./pages/facturacion-historico/facturacion-historico.module').then( m => m.FacturacionHistoricoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'facturacion-list',
    loadChildren: () => import('./pages/facturacion-list/facturacion-list.module').then( m => m.FacturacionListPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'facturacion-pendiente',
    loadChildren: () => import('./pages/facturacion-pendiente/facturacion-pendiente.module').then( m => m.FacturacionPendientePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recibir-express',
    loadChildren: () => import('./pages/recibir-express/recibir-express.module').then( m => m.RecibirExpressPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recibir-historico',
    loadChildren: () => import('./pages/recibir-historico/recibir-historico.module').then( m => m.RecibirHistoricoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recibir-list',
    loadChildren: () => import('./pages/recibir-list/recibir-list.module').then( m => m.RecibirListPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'entrega-list',
    loadChildren: () => import('./pages/entrega-list/entrega-list.module').then( m => m.EntregaListPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'entrega-historico',
    loadChildren: () => import('./pages/entrega-historico/entrega-historico.module').then( m => m.EntregaHistoricoPageModule)
  },
  {
    path: 'recibir-manual',
    loadChildren: () => import('./pages/recibir-manual/recibir-manual.module').then( m => m.RecibirManualPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'guia-despacho',
    loadChildren: () => import('./pages/guia-despacho/guia-despacho.module').then( m => m.GuiaDespachoPageModule)
  },  {
    path: 'documento-edit',
    loadChildren: () => import('./pages/documento-edit/documento-edit.module').then( m => m.DocumentoEditPageModule)
  },
  {
    path: 'monitor-obra-list',
    loadChildren: () => import('./pages/monitor-obra-list/monitor-obra-list.module').then( m => m.MonitorObraListPageModule)
  },
  {
    path: 'documento-pre-ingreso',
    loadChildren: () => import('./pages/documento-pre-ingreso/documento-pre-ingreso.module').then( m => m.DocumentoPreIngresoPageModule)
  },
  {
    path: 'documento-generado',
    loadChildren: () => import('./pages/documento-generado/documento-generado.module').then( m => m.DocumentoGeneradoPageModule)
  },
  {
    path: 'documento-pre-ingreso-list',
    loadChildren: () => import('./pages/documento-pre-ingreso-list/documento-pre-ingreso-list.module').then( m => m.DocumentoPreIngresoListPageModule)
  }





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
