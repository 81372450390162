
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserData } from '../providers/user-data';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor( private router: Router, private userData: UserData) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    
     return this.userData.isLoggedIn().then(res=>{

        if(res==true)
           return true;
       else {
        this.router.navigateByUrl('/login');
        return false;

      }})  ;
  
  }
  
}
